<template>
  <div>
    <BlockUI
      id="loading"
      :message="$t('loading')"
      v-if="loading"
      html="<i class='fa fa-cog fa-spin fa-2x fa-fw'></i>"
    />

    <CRow>
      <CCol>
        <ListCountry
          v-bind:tableItems="listCountries"
          @country-edit="showcardCountryEdit"
          @country-delete="showcardCountryDelete"
        />
      </CCol>
    </CRow>

    <transition name="slide-fade">
      <CardCountry
        v-if="showCardCountry"
        v-bind:country="countrySelected"
        v-bind:title="cardTitle"
        v-bind:notification="notification"
        v-bind:notificationType="notificationType"
        v-bind:btnEventText="btnEventText"
        v-bind:btnEventType="btnEventType"
        v-bind:inputsDisabled="inputsDisabled"
        v-bind:showCollapseBottom="showCollapseBottom"
        v-bind:listCountryCodes="listCountryCodes"
        @card-country-error="showToastError"
        @update-country="updateCountry"
        @delete-country="deleteCountry"
        @close-card-country="closeCardCountry"
      />
    </transition>

    <CToaster :autohide="3000" class="main-toast">
      <CToast :key="'toast'" :show="toastShow" :color="toastColor">
        {{ toastMessage }}
      </CToast>
    </CToaster>
  </div>
</template>

<script>
import ListCountry from "../components/ListCountry.vue";
import CardCountry from "../components/CardCountry";

export default {
  name: "Settings",
  components: {
    ListCountry,
    CardCountry,
  },
  data() {
    return {
      loading: true,

      listCountries: [],
      listCountryCodes: [],
      // props toast
      toastShow: false,
      toastColor: "",
      toastMessage: "",

      // props cardCountry
      showCardCountry: false,
      countrySelected: null,
      cardTitle: null,
      notification: null,
      notificationType: null,
      btnEventText: null,
      btnEventType: null,
      inputsDisabled: null,
      showCollapseBottom: null,
    };
  },
  async created() {
    await this.loadData();
    await this.loadCountryCodes();
  },
  methods: {
    async loadData() {
      const self = this;

      self.listCountries = [];

      try {
        const res = await self.$http.get("apiConsole/console/getCountries");
        self.listCountries = res.data.countries;
      } catch (e) {
        if (e.response) {
          const res = e.response;
          console.log(res.data.message);
        }
        console.error(e);
      }
      self.loading=false;
    },

    async loadCountryCodes() {
      const self = this;

      self.listCountryCodes = [];

      try {
        const res = await self.$http.get(
          "apiConsole/console/getTranslationCodes"
        );
        if (res.data && res.data.length > 0) {
          self.listCountryCodes = res.data.map((e) => e.toUpperCase());
        }
      } catch (e) {
        if (e.response) {
          const res = e.response;
          console.log(res.data.message);
        }
        console.error(e);
      }
    },

    async updateCountry(country) {
      const self = this;
      try {
        var countryToSave = {
          code: country.code,
          customerSatisfaction: country.customerSatisfaction,
          deliveryRejectedSignature: country.deliveryRejectedSignature,
          jobsiteSampling: country.jobsiteSampling,
          language: country.language,
          noSignatureReasonsList: country.noSignatureReasonsList,
          externalDeliverySignature: country.externalDeliverySignature,
          plantSampling: country.plantSampling,
          safetyCheckList: country.safetyCheckList,
          securityCodeType: country.securityCodeType,
          truckHoursLimit: country.truckHoursLimit,
          truckWashing: country.truckWashing,
          upgradeGPSInterval: country.upgradeGPSInterval,
          sendGeolocationInterval: country.sendGeolocationInterval,
          deliveryDisappearanceMinutes: country.deliveryDisappearanceMinutes,
        };
        const res = await self.$http.put(
          "apiConsole/console/updateCountryData",
          {
            country: countryToSave,
          }
        );
        console.log(res);
        if (res.data) {
          await self.loadData();
          await self.loadCountryCodes();

          self.showToastInfo("Country settings have been updated successfully");
          self.closeCardCountry(false);
        }
      } catch (e) {
        if (e.response) {
          const res = e.response;
          self.showToastError(res.data.message);
        }
        console.error(e);
      }
    },

    async deleteCountry(code) {
      const self = this;
      try {
        const res = await self.$http.put("apiConsole/console/deleteCountry", {
          code: code,
        });
        if (res.data.deletedCountry) {
          await self.loadData();
          await self.loadCountryCodes();

          self.showToastInfo("The country has been deleted successfully");
          self.closeCardCountry(false);
        }
      } catch (e) {
        if (e.response) {
          const res = e.response;
          self.showToastError(res.data.message);
        }
        console.error(e);
      }
    },

    showcardCountryEdit(cardCountry) {
      const self = this;

      self.showCardCountry = cardCountry.showCardCountry;

      self.countrySelected = cardCountry.countrySelected;
      self.cardTitle = cardCountry.cardTitle;

      self.notification = "Changes are only reflected when you save";
      self.notificationType = "warning";

      self.btnEventText = cardCountry.btnEventText;
      self.btnEventType = cardCountry.btnEventType;

      self.inputsDisabled = cardCountry.inputsDisabled;
      self.showCollapseBottom = true;
    },
    showcardCountryDelete(cardCountry) {
      const self = this;
      self.showCardCountry = cardCountry.showCardCountry;
      self.countrySelected = cardCountry.countrySelected;
      self.cardTitle = cardCountry.cardTitle;

      self.notification = cardCountry.notification;
      self.notificationType = cardCountry.notificationType;

      self.btnEventText = cardCountry.btnEventText;
      self.btnEventType = cardCountry.btnEventType;

      self.inputsDisabled = cardCountry.inputsDisabled;
      self.showCollapseBottom = true;
    },

    showToastError(msg) {
      const self = this;
      self.toastShow = true;
      self.toastColor = "danger";
      self.toastMessage = msg;

      self.sleep(3000).then(() => {
        self.toastShow = false;
      });
    },
    showToastInfo(msg) {
      const self = this;
      self.toastShow = true;
      self.toastColor = "success";
      self.toastMessage = msg;

      self.sleep(3000).then(() => {
        self.toastShow = false;
      });
    },

    async closeCardCountry(toggle) {
      // TODO: reseteamos las props del cardDriver
      this.showCardCountry = toggle;
      this.countrySelected = null;
      this.cardTitle = null;
      this.notification = null;
      this.notificationType = null;
      this.btnEventText = null;
      this.btnEventType = null;
      this.inputsDisabled = null;

      // cargamos los datos
      await this.loadData();
      await this.loadCountryCodes();
    },
  },
};
</script>

<style lang="scss" scoped>
.main-toast {
  z-index: 11000;
}

.slide-fade-enter-active {
  transition: all 0.4s ease;
}
.slide-fade-leave-active {
  transition: all 0.2s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
</style>